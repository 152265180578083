import React from 'react';
import lodash from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { alt } from '../../../lib/brand';
import BlogCategory from '../../../components/BlogCategory';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import { mapBlogs } from '../../../lib/utility';
import { Helmet } from 'react-helmet';

const DIYProjects = ({ data }) => {
   const { blogs, featBlogs } = useStaticQuery(graphql`
      query DIYProjectsQuery {
         blogs: allBlog(
            limit: 100
            sort: { fields: [date], order: DESC }
            filter: { categories: { in: ["DIY Projects"] }, type: { eq: "corporate" } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
               }
            }
         }
         featBlogs: allBlog(
            limit: 20
            sort: { fields: [date], order: DESC }
            filter: { categories: { in: ["DIY Projects"] }, feature: { eq: true } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
               }
            }
         }
      }
   `);

   const { edges: catBlogPosts = [] } = blogs || {};
   let catPosts = catBlogPosts;
   const { edges: featBlobPosts = [] } = featBlogs || {};
   catPosts.push(...featBlobPosts);
   catPosts.sort((a, b) => {
      return new Date(b.node.date) - new Date(a.node.date);
   });
   let catBlogs = mapBlogs(catPosts);
   catBlogs = lodash.uniqBy(catBlogs, 'id');
   let props = {
      catBlogs
   };
   const header = <Header active={['tools-and-resources', 'bay-equity-news']} theme="blue-ribbon" />;
   return (
      <Layout hasHero={false} header={header}>
         <Helmet>
            <title>{alt('Bay Equity News - DIY Projects')}</title>
            <meta
               name="description"
               content="Welcome to the DIY Projects Category. Check out our range of DIY Project blog posts that you can do around your home. Remember, we’re here to get you home."
            />
         </Helmet>
         <BlogCategory {...props} categories={['DIY Projects']} />
      </Layout>
   );
};
export default DIYProjects;
